import { useQuery } from '@tanstack/react-query';
import { ErrorResponse, Wallet } from 'types';

import { url } from 'statics';
import { TableListData } from 'utils/ProTableRequest';

const useUserWallets = ({user_id}: {user_id:string}) =>
  useQuery<TableListData<Wallet>, ErrorResponse>([`${url.wallets}?user=${user_id}`]);

export default useUserWallets;
