import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import type { ErrorResponse } from "types";

const useDowngradeToLvl1 = (): UseMutationResult<
  {},
  ErrorResponse,
  { id: string }
> => useMutation(({ id }) => api.put(url.downgradeToLvl1, { user: id }));

export default useDowngradeToLvl1;
