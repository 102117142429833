import { default as transactionColumns } from "pages/Transactions/columns";
import { Columns, Transaction } from "types";

const columns: Columns<Transaction> = ({
  actions,
  role,
}: {
  actions: any;
  role: string;
}) =>
  transactionColumns({ actions, currencyList: {} });

export default columns;
