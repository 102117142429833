import { Card, Descriptions, Flex, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { Transaction } from "types";

type SourceInfoProps = {
  transactionLoading: boolean;
  transaction?: Transaction;
};

const SourceInfo: React.FC<SourceInfoProps> = ({
  transactionLoading,
  transaction,
}) => {
  const cryptoData = transaction?.crypto_withdrawal_account?.title
    ? [
        {
          key: "5",
          label: "Crypto wallet title",
          children: (
            <Typography>
              {transaction?.crypto_withdrawal_account?.title}
            </Typography>
          ),
        },
        {
          key: "6",
          label: "Wallet address",
          children: (
            <Typography>
              {transaction?.crypto_withdrawal_account?.wallet_address}
            </Typography>
          ),
        },
      ]
    : [];
  const cryptoResult = transaction?.form?.withdraw_crypto
    ? [
        {
          key: "7",
          label: "Created at on Bitgo",
          children: (
            <Typography>
              {dayjs(transaction?.form?.withdraw_crypto.createdTime).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            </Typography>
          ),
        },
        {
          key: "8",
          label: "Bitgo wallet type",
          children: (
            <Typography>
              {transaction?.form?.withdraw_crypto.walletType}
            </Typography>
          ),
        },
        {
          key: "9",
          label: "Transaction ID",
          children: (
            <Typography>{transaction?.form?.withdraw_crypto.txid}</Typography>
          ),
        },
      ]
    : [];

  return (
    <Card>
      {transactionLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : transaction?.source_wallet ? (
        <Descriptions
          bordered
          layout="vertical"
          title="Source wallet info"
          items={[
            {
              key: "1",
              label: "Wallet",
              children: (
                <Flex gap={8}>
                  <img
                    src={transaction.source_wallet?.currency?.icon}
                    alt="symbol"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <Typography.Text>
                    {transaction.source_wallet?.currency?.title || "-"}
                  </Typography.Text>
                </Flex>
              ),
            },
            {
              key: "1.1",
              label: "Wallet amount",
              children: (
                <Flex gap={8}>
                  <Typography.Text>
                    {(
                      (transaction.source_wallet?.amount ||
                        0) as unknown as number
                    ).toFixed(5)}
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        paddingLeft: 2,
                        color: "green",
                      }}
                    >
                      {transaction?.source_wallet?.currency?.symbol || ""}
                    </Typography.Text>
                  </Typography.Text>
                </Flex>
              ),
            },
            {
              key: "1.2",
              label: "Wallet freezed amount",
              children: (
                <Flex gap={8}>
                  <Typography.Text>
                    {(
                      (transaction.source_wallet?.frozen_amount ||
                        0) as unknown as number
                    ).toFixed(5)}
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        paddingLeft: 2,
                        color: "green",
                      }}
                    >
                      {transaction?.source_wallet?.currency?.symbol || ""}
                    </Typography.Text>
                  </Typography.Text>
                </Flex>
              ),
            },
            {
              key: "2",
              label: "User name",
              children: (
                <Typography.Text>
                  {(transaction.source_wallet.user?.profile?.first_name || "") +
                    " " +
                    (transaction.source_wallet.user?.profile?.last_name || "")}
                </Typography.Text>
              ),
            },
            {
              key: "3",
              label: "User email",
              children: (
                <Typography.Text>
                  {transaction.source_wallet.user?.email||'-'}
                </Typography.Text>
              ),
            },
            {
              key: "4",
              label: "User identifier",
              children: (
                <Typography.Text>
                  {transaction.source_wallet.user?.identifier_number||'-'}
                </Typography.Text>
              ),
            },
            ...cryptoData,
            ...cryptoResult,
          ]}
        />
      ) : (
        <Typography>
          This transaction doesn't have source wallet in our system
        </Typography>
      )}
    </Card>
  );
};

export default SourceInfo;
