import { CheckOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";
import { Columns, Transaction } from "types";

const columns: Columns<Transaction> = ( actions: any) => [
  {
    title: "Identity",
    dataIndex: "identity",
  },
  {
    title: "Primary",
    dataIndex: "is_primary",
    render: (_: any, { is_primary }: { is_primary: boolean }) => {
      return is_primary ? <CheckOutlined /> : "";
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    valueEnum: {
      VERIFIED: { text: <Tag color="green">Verified</Tag> },
      NOT_VERIFIED: { text: <Tag color="red">Not verified</Tag> },
    },
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    render: (_: any, { created_at }: { created_at: string }) => {
      return dayjs(created_at).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "Actions",
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
