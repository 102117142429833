import React from 'react';
import { queyWalletWhitelist } from './services';
import ProTable from 'components/ProTable';
import columns from './columns';
import type { Whitelist } from 'types';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { ProTableRequest, reloadTable } from 'utils';
import { useParams } from 'react-router-dom';
import { useUser } from 'hooks';
import { ApproveWallet } from './components';
import { ActionType } from '@ant-design/pro-components';

const WalletWhitelist: React.FC = () => {
  const actionRef = React.useRef<ActionType>();
  const { walletId } = useParams();

  const { data: user } = useUser({ id: walletId || '' });

  const whitelistRequest = ProTableRequest<Whitelist>(
    ({ params }: { params: any }) =>
      queyWalletWhitelist({ params, walletId: walletId || '' })
  )({});

  const actions = (_: any, item: Whitelist) => [
    item.status === 'NOT_VERIFIED' && (
      <ApproveWallet
        itemID={item.id}
        onApprove={() => reloadTable(actionRef)}
      />
    ),
  ];

  return (
    <ProTable<Whitelist>
      actionRef={actionRef}
      headerTitle={
        <Flex style={{ width: '100%' }} align="center">
          <Title level={5}>
            {(user?.username
              ? user?.username
              : user?.profile.first_name && user?.profile.last_name
              ? user?.profile.first_name + ' ' + user?.profile.last_name
              : '') + ' whitelist'}
          </Title>
        </Flex>
      }
      columns={columns(actions)}
      request={whitelistRequest}
      options={false}
      search={false}
    />
  );
};

export default WalletWhitelist;
