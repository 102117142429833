import { Button, Modal } from "antd";
import { useKYCBlockUser } from "hooks";
import React from "react";

type KycUnblockUserModalProps = {
  userId: string;
  is_kyc_blocked: boolean;
  refresh: () => void;
};

const KycUnblockUserModal: React.FC<KycUnblockUserModalProps> = ({
  userId,
  refresh,
  is_kyc_blocked,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: blockUser, isLoading: blockLoading } = useKYCBlockUser();

  const onFinish = async () => {
    blockUser({ user_id: userId, is_kyc_blocked: !is_kyc_blocked }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: "unset", padding: "0 4px", color: "red" }}
        loading={blockLoading}
      >
        {is_kyc_blocked ? "KYC(SumSub) Unblock user" : "KYC(SumSub) Block user"}
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => onFinish()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title={is_kyc_blocked ? "KYC(SumSub) Unblock user" : "KYC(SumSub) Block user"}
        cancelText="Close"
        okText="Confirm"
        okButtonProps={{ danger: true }}
        destroyOnClose
      >
        {is_kyc_blocked
          ? "You want to unblock this user in KYC(SumSub). Are you sure?"
          : "You want to block this user in KYC(SumSub). Are you sure?"}
      </Modal>
    </>
  );
};
export default KycUnblockUserModal;
