import {
  ProForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { FormInstance } from "antd/lib";
import React from "react";
import { CreateContactFormType } from "types";
import { generateFormProps } from "utils";

type CreateContactFormProps = {
  form: FormInstance;
  onFinish: (data: CreateContactFormType) => Promise<void>;
  error: any;
};

const CreateContactForm: React.FC<CreateContactFormProps> = ({
  form,
  error,
  onFinish,
}) => {
  const rules = {
    type: [{ required: true }],
    identity: [{ required: true }],
    status: [{ required: true }],
  };

  const formProps = generateFormProps(
    rules,
    { error },
    { identity: "Identity", status: "Status", type: "Type" }
  );

  return (
    <ProForm
      form={form}
      onFinish={async (values) => {
        onFinish(values).then(() => form.resetFields());
      }}
      submitter={false}
      dateFormatter={false}
    >
      <ProFormSelect
        {...formProps.type}
        options={[
          { label: "Email", value: "EMAIL" },
          { label: "Phone", value: "PHONE" },
        ]}
      />
      <ProFormText {...formProps.identity} />
      <ProFormSelect
        {...formProps.status}
        options={[
          { label: "verified", value: "VERIFIED" },
          { label: "not verified", value: "NOT_VERIFIED" },
        ]}
      />
    </ProForm>
  );
};
export default CreateContactForm;
