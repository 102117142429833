import { Tag, Typography } from "antd";
import { Columns, Fee, Transaction, TransactionMethod } from "types";

const columns: Columns<Transaction> = ({
  actions,
  currencyList,
}: {
  actions: any;
  currencyList: { [x: string]: { text: string } }[];
}) => [
  {
    title: "Type",
    dataIndex: ["transaction_method", "type"],
    valueEnum: {
      DEPOSIT: { text: <Tag color="green">Deposit</Tag> },
      WITHDRAW: { text: <Tag color="red">Withdraw</Tag> },
      TRANSFER: { text: <Tag color="purple">Transfer</Tag> },
      SWAP: { text: <Tag color="geekblue">Swap</Tag> },
    },
  },
  {
    title: "Category",
    dataIndex: ["transaction_method", "category"],
    valueEnum: {
      WITHDRAW_BANK_TRANSFER: {
        text: <Tag color="red">Withdraw bank transfer</Tag>,      },
      DEPOSIT_BANK_TRANSFER: {
        text: <Tag color="green">Deposit bank transfer</Tag>,      },
      WITHDRAW_CRYPTO: { text: <Tag color="red">Withdraw crypto</Tag> },
      DEPOSIT_CRYPTO: { text: <Tag color="green">Deposit crypto</Tag> },
      WITHDRAW_LD: { text: <Tag color="red">Withdraw LD</Tag> },
      DEPOSIT_LD: { text: <Tag color="green">Deposit LD</Tag> },
      WITHDRAW_BROKER: { text: <Tag color="red">Withdraw Broker</Tag> },
      DEPOSIT_BROKER: { text: <Tag color="green">Deposit Broker</Tag> },
      WITHDRAW_MANUAL: { text: <Tag color="red">Withdraw manual</Tag> },
      DEPOSIT_MANUAL: { text: <Tag color="green">Deposit manual</Tag> },
      SWAP: { text: <Tag color="geekblue">Swap</Tag> },
      TRANSFER_USER: { text: <Tag color="purple">Transfer</Tag> },
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (
      _: string,
      {
        amount,
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        amount: number;
        source_wallet: Transaction["source_wallet"];
        destination_wallet: Transaction["destination_wallet"];
        transaction_method: TransactionMethod;
      }
    ) => {
      const trCategory = transaction_method.category;
      const symbol = {
        DEPOSIT_BANK_TRANSFER: destination_wallet?.currency?.symbol || "",
        DEPOSIT_CRYPTO: destination_wallet?.currency?.symbol || "",
        DEPOSIT_LD: source_wallet?.currency?.symbol || "",
        DEPOSIT_BROKER: destination_wallet?.currency?.symbol || "",
        WITHDRAW_BANK_TRANSFER: source_wallet?.currency?.symbol || "",
        WITHDRAW_CRYPTO: source_wallet?.currency?.symbol || "",
        WITHDRAW_LD: destination_wallet?.currency?.symbol || "",
        WITHDRAW_BROKER: source_wallet?.currency?.symbol || "",
        SWAP: source_wallet?.currency?.symbol || "",
        TRANSFER_USER: source_wallet?.currency?.symbol || "",
      };

      return (
        <Typography.Text>
          {amount ? amount.toFixed(5) : "-"}
          <Typography.Text
            style={{ fontSize: "10px", paddingLeft: 2, color: "green" }}
          >
            {symbol[trCategory]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: "Destination amount",
    dataIndex: "destination_amount",
    render: (
      _: string,
      {
        destination_amount,
        source_wallet,
        destination_wallet,
        transaction_method,
      }: {
        destination_amount: number;
        source_wallet: Transaction["source_wallet"];
        destination_wallet: Transaction["destination_wallet"];
        transaction_method: TransactionMethod;
      }
    ) => {
      const trCategory = transaction_method.category;
      const isCrypto = ["WITHDRAW_CRYPTO", "DEPOSIT_CRYPTO"].includes(
        transaction_method.category
      );

      const symbol = {
        DEPOSIT_BANK_TRANSFER: destination_wallet?.currency?.symbol || "",
        DEPOSIT_CRYPTO: destination_wallet?.currency?.symbol || "",
        DEPOSIT_LD: destination_wallet?.currency?.symbol || "",
        DEPOSIT_BROKER: destination_wallet?.currency?.symbol || "",
        WITHDRAW_BANK_TRANSFER: source_wallet?.currency?.symbol || "",
        WITHDRAW_CRYPTO: source_wallet?.currency?.symbol || "",
        WITHDRAW_LD: source_wallet?.currency?.symbol || "",
        WITHDRAW_BROKER: source_wallet?.currency?.symbol || "",
        SWAP: destination_wallet?.currency?.symbol || "",
        TRANSFER_USER: destination_wallet?.currency?.symbol || "",
      };

      return (
        <Typography.Text>
          {destination_amount ? destination_amount.toFixed(5) : "-"}
          <Typography.Text
            style={{ fontSize: "10px", paddingLeft: 2, color: "green" }}
          >
            {symbol[trCategory]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: "Fee",
    dataIndex: "fee",
    render: (
      _: string,
      {
        source_wallet,
        destination_wallet,
        fee,
        transaction_method,
      }: {
        source_wallet: Transaction["source_wallet"];
        destination_wallet: Transaction["destination_wallet"];
        fee: Fee;
        transaction_method: TransactionMethod;
      }
    ) => {
      const trCategory = transaction_method.category;
      const symbol = {
        DEPOSIT_BANK_TRANSFER: destination_wallet?.currency?.symbol || "",
        DEPOSIT_CRYPTO: destination_wallet?.currency?.symbol || "",
        DEPOSIT_LD: source_wallet?.currency?.symbol || "",
        DEPOSIT_BROKER: destination_wallet?.currency?.symbol || "",
        WITHDRAW_BANK_TRANSFER: source_wallet?.currency?.symbol || "",
        WITHDRAW_CRYPTO: source_wallet?.currency?.symbol || "",
        WITHDRAW_LD: destination_wallet?.currency?.symbol || "",
        WITHDRAW_BROKER: source_wallet?.currency?.symbol || "",
        SWAP: source_wallet?.currency?.symbol || "",
        TRANSFER_USER: source_wallet?.currency?.symbol || "",
      };

      return (
        <Typography.Text>
          {fee.fee_amount || 0}
          <Typography.Text
            style={{ fontSize: "10px", paddingLeft: 2, color: "green" }}
          >
            {symbol[trCategory]}
          </Typography.Text>
        </Typography.Text>
      );
    },
    hideInSearch: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    valueEnum: {
      INIT: { text: "Init", status: "Default" },
      AUTHORIZED: { text: "Authorized", status: "Processing" },
      UNAUTHORIZED: { text: "Unauthorized", status: "Error" },
      CLEARED: { text: "Cleared", status: "Processing" },
      NOT_CLEARED: { text: "Not cleared", status: "Error" },
      APPROVED: { text: "Approved", status: "Processing" },
      DENIED: { text: "Denied", status: "Error" },
      SETTLING: { text: "Settling", status: "Processing" },
      FAILED: { text: "Failed", status: "Error" },
      SETTLED: { text: "Settled", status: "Success" },
      CANCELED: { text: "Canceled", status: "Error" },
    },
  },
  {
    title: "Source wallet email",
    dataIndex: ["source_wallet", "user", "email"],
    hideInSearch: true,
  },
  {
    title: "Destination wallet email",
    dataIndex: ["destination_wallet", "user", "email"],
    hideInSearch: true,
  },
  {
    title: "currency",
    dataIndex: "currency",
    hideInTable: true,
    valueEnum: currencyList,
  },
  {
    title: "username",
    dataIndex: "username",
    hideInTable: true,
  },
  {
    title: "Last update",
    dataIndex: "updated_at",
    valueType: "dateRange",
    hideInTable: true,
    search: {
      transform: (value: string[]) => {
        return { from_date: value[0], to_date: value[1] };
      },
    },
  },
  {
    title: "Updated at",
    dataIndex: "updated_at",
    valueType: "dateTime",
    hideInSearch: true,
    sorter: true,
  },
  {
    title: "Actions",
    render: actions,
    hideInSearch: true,
  },
];

export default columns;
