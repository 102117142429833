import { Flex, Spin, Typography } from "antd";
import { ProTable } from "components";
import { Contact } from "types";
import { ProTableRequest, reloadTable } from "utils";
import { queyContacts } from "./service";
import columns from "./columns";
import React from "react";
import CreateContact from "../CreateContact";
import { ActionType } from "@ant-design/pro-components";
import SetPrimaryModal from "../SetPrimaryModal";
import DeleteContactModal from "../DeleteContactModal";

type ContactInfoProps = {
  userId: string;
  isLoading: boolean;
};

const ContactInfo: React.FC<ContactInfoProps> = ({ userId, isLoading }) => {
  const actionRef = React.useRef<ActionType>();

  const contactsRequest = ProTableRequest<Contact>(
    ({ params }: { params: any }) =>
      queyContacts({ params, userId: userId || "" })
  )();

  const action = (_: any, item: any) => [
    !item?.is_primary && (
      <DeleteContactModal
        refresh={() => reloadTable(actionRef)}
        contactId={item.id}
        userId={userId}
      />
    ),
    !item?.is_primary && item.status === 'VERIFIED' && (
      <SetPrimaryModal
        refresh={() => reloadTable(actionRef)}
        contactId={item.id}
        userId={userId}
        contact={item.identity}
      />
    ),
  ];

  return (
    <>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <ProTable
          headerTitle={
            <Typography.Text
              style={{
                padding: "20px 0",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Contact Information
            </Typography.Text>
          }
          toolBarRender={() => [
            <CreateContact
              refresh={() => reloadTable(actionRef)}
              userId={userId}
            />,
          ]}
          actionRef={actionRef}
          columns={columns(action)}
          request={contactsRequest}
          options={false}
          search={false}
          pagination={false}
        />
      )}
    </>
  );
};

export default ContactInfo;
