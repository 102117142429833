import { Button, Divider, Form, Modal } from "antd";
import { useChangeUsername } from "hooks";
import React from "react";
import { ChangeUsername } from "types";
import ChangeUsernameForm from "../ChangeUsernameForm";

type ChangeUsernameModalProps = {
  username: string;
  userId: string;
  refresh: () => void;
};

const ChangeUsernameModal: React.FC<ChangeUsernameModalProps> = ({
  username,
  userId,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const { mutateAsync: change, isLoading, error } = useChangeUsername();

  const onFinish = (data: ChangeUsername) => {
    change({ username: data.username, user: userId }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: "unset", padding: "0 4px" }}
        loading={isLoading}
      >
        Change username
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title='Change username'
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        <Divider type="horizontal" />
        <ChangeUsernameForm
          form={form}
          onFinish={onFinish}
          error={error}
          username={username}
        />
      </Modal>
    </>
  );
};
export default ChangeUsernameModal;
