import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import { ErrorResponse, ManualTransactionRequest } from "types";

const useManualTransaction = ({
  type,
}: {
  type: "deposit" | "withdraw";
}): UseMutationResult<
  { id: string },
  ErrorResponse,
  ManualTransactionRequest
> =>
  useMutation((data) =>
    api.post(
      type === "deposit"
        ? url.depositManualTransaction
        : url.withdrawManualTransaction,
      data
    )
  );

export default useManualTransaction;
