import { Button, Divider, Form, Modal } from "antd";
import { useCreateContact } from "hooks";
import React from "react";
import CreateContactForm from "../CreateContactForm";
import { CreateContactFormType } from "types";

type CreateContactProps = {
  refresh: () => void;
  userId: string;
};

const CreateContact: React.FC<CreateContactProps> = ({ refresh, userId }) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const {
    mutateAsync: createContact,
    isLoading: updating,
    error,
  } = useCreateContact();

  const onFinish = async (data: CreateContactFormType) => {
    createContact({ userId, data }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="primary"
        style={{ minHeight: "unset", padding: "0 4px" }}
        loading={updating}
      >
        Create
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Upgrade user to LD"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        <Divider type="horizontal" />
        <CreateContactForm form={form} onFinish={onFinish} error={error} />
      </Modal>
    </>
  );
};
export default CreateContact;
