import { Button, Popconfirm } from "antd";
import { useDowngradeToLvl1 } from "hooks";
import React from "react";

type DowngradeToLvl1ModalProps = {
  username: string;
  userId: string;
  refresh: () => void;
};

const DowngradeToLvl1: React.FC<DowngradeToLvl1ModalProps> = ({
  username,
  userId,
  refresh,
}) => {
  const { mutateAsync: downgradeToLvl1, isLoading: downgradeLoading } =
    useDowngradeToLvl1();

  return (
    <Popconfirm
      title={`Downgrade the user (${useDowngradeToLvl1})`}
      description="Are you sure to downgrade this user?"
      okText="Yes"
      cancelText="No"
      onConfirm={() => downgradeToLvl1({ id: userId })}
    >
      <Button
        type="link"
        style={{ minHeight: "unset", padding: "0 4px", color: "red" }}
        loading={downgradeLoading}
      >
        Downgrade to level 1
      </Button>
    </Popconfirm>
  );
};

export default DowngradeToLvl1;
