import { RouterProvider } from "react-router-dom";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createQueryClient } from "./utils";
import { router } from "./wrappers";
import { ConfigProvider } from "antd";
import en from "antd/locale/en_US";
import { NuqsAdapter } from "nuqs/adapters/react";

function App() {
  const [queryClient] = React.useState(() => createQueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={en}>
        <NuqsAdapter>
          <RouterProvider router={router} />
        </NuqsAdapter>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
