import { ProForm, ProFormText } from "@ant-design/pro-components";
import { Alert, Flex, Typography } from "antd";
import { FormInstance } from "antd/lib";
import React from "react";
import { ChangeUsername } from "types";
import { generateFormProps } from "utils";

type ChangeUsernameFormProps = {
  form: FormInstance;
  data?: ChangeUsername;
  onFinish: (data: ChangeUsername) => void;
  error: any;
  username: string;
};

const ChangeUsernameForm: React.FC<ChangeUsernameFormProps> = ({
  form,
  data,
  error,
  username,
  onFinish,
}) => {
  const [isAlert, setIsAlert] = React.useState<ChangeUsername | null>(null);

  const rules = { username: [{ required: true }] };

  const formProps = generateFormProps(
    rules,
    { error },
    { username: "Username" }
  );

  return (
    <ProForm
      form={form}
      onFinish={async (values) => {
        if (isAlert) {
          onFinish(values);
        }
        setIsAlert(values);
      }}
      submitter={false}
      dateFormatter={false}
      initialValues={data}
    >
      <Flex dir="row" justify="space-between" align="center">
        <Typography>Current username : </Typography>
        <Typography style={{ fontWeight: "bold" }}>{username}</Typography>
      </Flex>
      <br />
      <ProFormText {...formProps.username} />
      {isAlert && (
        <Alert
          message="Are you sure you want to do that?"
          description={
            <Typography.Text>
              The username will change to{" "}
              <Typography.Text style={{ fontWeight: "bold", paddingInline: 8 }}>
                {form.getFieldValue("username")}
              </Typography.Text>
              <br />
              Current username:
              <Typography.Text style={{ fontWeight: "bold", paddingInline: 8 }}>
                {username}
              </Typography.Text>
            </Typography.Text>
          }
          type="warning"
          showIcon
        />
      )}
    </ProForm>
  );
};
export default ChangeUsernameForm;
