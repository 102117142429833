import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse } from 'types';

const useKYCBlockUser = (): UseMutationResult<
  {},
  ErrorResponse,
  { user_id: string; is_kyc_blocked: boolean }
> =>
  useMutation(({ user_id, is_kyc_blocked }) =>
    api.put(url.kycBlockUser(user_id), { is_kyc_blocked })
  );

export default useKYCBlockUser;
