import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import type { ErrorResponse } from "types";

const useChangeUsername = (): UseMutationResult<
  {},
  ErrorResponse,
  { user: string; username: string }
> =>
  useMutation(({ user, username }) =>
    api.put(url.changeUsername(user), { username: username.trim() })
  );

export default useChangeUsername;
