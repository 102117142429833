import { Button, Modal } from "antd";
import { useDeleteContact } from "hooks";
import React from "react";

type DeleteContactModalProps = {
  userId: string;
  contactId: string;
  refresh: () => void;
};

const DeleteContactModal: React.FC<DeleteContactModalProps> = ({
  userId,
  contactId,
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: deleteContact, isLoading: deleteLoading } =
    useDeleteContact();

  const onFinish = async () => {
    deleteContact({ userId, contactId }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: "unset", padding: "0 4px", color: "red" }}
        loading={deleteLoading}
        danger
      >
        Delete
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => onFinish()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Delete Contact"
        cancelText="Close"
        okText="Confirm"
        okButtonProps={{ danger: true }}
        destroyOnClose
      >
        Are you sure you want to delete this contact?
      </Modal>
    </>
  );
};
export default DeleteContactModal;
