import { ErrorResponse, ListResponse, Whitelist } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyWalletWhitelist = ({
  params,
  walletId,
}: {
  params: any;
  walletId: string;
}) => {
  return api.get<ListResponse<Whitelist>, ErrorResponse, any>(url.whitelist, {
    params: {
      // ...params,
      user: walletId,
    },
  });
};

export { queyWalletWhitelist };
