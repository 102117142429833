import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import { ErrorResponse } from "types";

const useApproveManualTransaction = ({
  type,
  id,
}: {
  type: "deposit" | "withdraw";
  id: string;
}): UseMutationResult<
  { id: string },
  ErrorResponse
> =>
  useMutation((data) =>
    api.put(
      type === "deposit"
        ? url.approveDepositManualTransaction(id)
        : url.approveWithdrawManualTransaction(id),
      data
    )
  );

export default useApproveManualTransaction;
