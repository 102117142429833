import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import type { ErrorResponse } from "types";

const useCreateContact = (): UseMutationResult<
  {},
  ErrorResponse,
  { userId: string; data: Record<string, string> }
> =>
  useMutation(({ userId, data }) => api.post(url.createContact(userId), data));

export default useCreateContact;
