import {
  ProForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { Alert, Form, Modal } from "antd";
import {
  useApproveManualTransaction,
  useManualTransaction,
  useUserWallets,
} from "hooks";
import React from "react";
import { ManualTransactionRequest } from "types";
import { generateFormProps } from "utils";

type ManualTransactionType = {
  userId: string;
  type: "deposit" | "withdraw";
  onClose: () => void;
};
const ManualTransaction: React.FC<ManualTransactionType> = ({
  userId,
  type,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [confirmationID, setConfirmationID] = React.useState<null | string>(
    null
  );

  const {
    mutateAsync: ManualTr,
    isLoading: loadingManualTr,
    error,
  } = useManualTransaction({ type });

  const { data: wallets } = useUserWallets({ user_id: userId });

  const formProps = generateFormProps(
    {
      source_wallet: [{ required: type === "withdraw" }],
      destination_wallet: [{ required: type === "deposit" }],
      amount: [{ required: true }],
    },
    { error },
    {
      source_wallet: "Source wallet",
      destination_wallet: "Destination wallet",
      amount: "Amount",
    }
  );

  const selectedWallet = wallets?.list?.find(
    (item) =>
      item.id === form.getFieldValue("destination_wallet") ||
      item.id === form.getFieldValue("source_wallet")
  );

  const { mutateAsync: approve, isLoading: approveLoading } =
    useApproveManualTransaction({ id: confirmationID || "", type });

  return (
    <Modal
      open={true}
      onOk={async () =>
        confirmationID ? approve({}).then(() => onClose()) : form.submit()
      }
      onCancel={() => onClose()}
      maskClosable={false}
      title={`Manual ${type}`}
      cancelText="Close"
      okText="Confirm"
      destroyOnClose
      confirmLoading={loadingManualTr || approveLoading}
    >
      <br />
      <ProForm<ManualTransactionRequest>
        form={form}
        onFinish={async (values) =>
          void ManualTr(values).then((result) => setConfirmationID(result.id))
        }
        submitter={false}
        dateFormatter={false}
      >
        {type === "withdraw" && (
          <ProFormSelect
            {...formProps.source_wallet}
            options={wallets?.list?.map((wallet) => ({
              label: wallet.currency.title,
              value: wallet.id,
            }))}
            disabled={confirmationID}
          />
        )}
        {type === "deposit" && (
          <ProFormSelect
            {...formProps.destination_wallet}
            options={wallets?.list?.map((wallet) => ({
              label: wallet.currency.title,
              value: wallet.id,
            }))}
            disabled={confirmationID}
          />
        )}
        <ProFormDigit {...formProps.amount} disabled={confirmationID} />
      </ProForm>
      {confirmationID && (
        <Alert
          message="Are you sure?"
          type="warning"
          description={
            type === "deposit"
              ? `You want to deposit ${form.getFieldValue("amount")} ${
                  selectedWallet?.currency.symbol
                } to ${selectedWallet?.currency.title}`
              : `You want to withdraw ${form.getFieldValue("amount")} ${
                  selectedWallet?.currency.symbol
                } from ${selectedWallet?.currency.title}`
          }
          showIcon
        />
      )}
    </Modal>
  );
};
export default ManualTransaction;
