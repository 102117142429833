import { Card, Descriptions, Flex, Spin, Tag, Typography } from "antd";
import { Transaction } from "types";

type MetadataProps = {
  transactionLoading: boolean;
  transaction: Transaction | undefined;
};

const Metadata: React.FC<MetadataProps> = ({
  transactionLoading,
  transaction,
}) => {
  const { Text, Link } = Typography;

  const WITHDRAW_BROKER_Metadata = [
    {
      key: "0",
      label: "Payee account",
      children: (
        <Text>{transaction?.form?.withdraw_broker?.payee_account}</Text>
      ),
    },
    {
      key: "1",
      label: "Payee name",
      children: <Text>{transaction?.form?.withdraw_broker?.payee_name}</Text>,
    },
    {
      key: "2",
      label: "Client email",
      children: <Text>{transaction?.form?.withdraw_broker?.client_email}</Text>,
    },
    {
      key: "3",
      label: "Client name",
      children: <Text>{transaction?.form?.withdraw_broker?.client_name}</Text>,
    },
    {
      key: "4",
      label: "Currency",
      children: <Text>{transaction?.form?.withdraw_broker?.currency}</Text>,
    },
    {
      key: "5",
      label: "Payment ID",
      children: <Text>{transaction?.form?.withdraw_broker?.payment_id}</Text>,
    },
    {
      key: "6",
      label: "Status url",
      children: (
        <Link
          href={transaction?.form?.withdraw_broker?.status_url}
          target="_blank"
        >
          {transaction?.form?.withdraw_broker?.status_url}
        </Link>
      ),
    },
    {
      key: "7",
      label: "Payment url",
      children: (
        <Flex gap={4}>
          <Tag color="orange" style={{ height: "max-content" }}>
            {transaction?.form?.withdraw_broker?.payment_url_method}
          </Tag>
          <Link
            href={transaction?.form?.withdraw_broker?.payment_url}
            target="_blank"
          >
            {transaction?.form?.withdraw_broker?.payment_url}
          </Link>
        </Flex>
      ),
    },
    {
      key: "8",
      label: "No payment url",
      children: (
        <Flex gap={4}>
          <Tag color="orange" style={{ height: "max-content" }}>
            {transaction?.form?.withdraw_broker?.nopayment_url_method}
          </Tag>
          <Link
            href={transaction?.form?.withdraw_broker?.nopayment_url}
            target="_blank"
          >
            {transaction?.form?.withdraw_broker?.nopayment_url}
          </Link>
        </Flex>
      ),
    },
    {
      key: "9",
      label: "Trading account",
      children: (
        <Text>{transaction?.form?.withdraw_broker?.trading_account}</Text>
      ),
    },
    {
      key: "10",
      label: "Magix ID",
      children: <Text>{transaction?.form?.withdraw_broker?.magix_id}</Text>,
    },
    {
      key: "11",
      label: "Suggested memo",
      children: (
        <Text>{transaction?.form?.withdraw_broker?.suggested_memo}</Text>
      ),
    },
  ];

  const DEPOSIT_BROKER_Metadata = [
    {
      key: "0",
      label: "Payee account",
      children: <Text>{transaction?.form?.deposit_broker?.payment_id}</Text>,
    },
    {
      key: "1",
      label: "Payee name",
      children: <Text>{transaction?.form?.deposit_broker?.payer_name}</Text>,
    },
    {
      key: "2",
      label: "Client email",
      children: <Text>{transaction?.form?.deposit_broker?.client_name}</Text>,
    },
    {
      key: "3",
      label: "Client name",
      children: <Text>{transaction?.form?.deposit_broker?.client_email}</Text>,
    },
    {
      key: "4",
      label: "Currency",
      children: <Text>{transaction?.form?.deposit_broker?.currency}</Text>,
    },
    {
      key: "5",
      label: "Trading account",
      children: (
        <Text>{transaction?.form?.deposit_broker?.trading_account}</Text>
      ),
    },
    {
      key: "6",
      label: "Magix ID",
      children: <Text>{transaction?.form?.deposit_broker?.magix_id}</Text>,
    },
    {
      key: "7",
      label: "Suggested memo",
      children: (
        <Text>{transaction?.form?.deposit_broker?.suggested_memo}</Text>
      ),
    },
  ];

  const TRANSFER_Metadata = [
    {
      key: "0",
      label: "Source identifier number",
      children: (
        <Text>{transaction?.form?.transfer?.source_identifier_number}</Text>
      ),
    },
    {
      key: "1",
      label: "Destination identifier number",
      children: (
        <Text>
          {transaction?.form?.transfer?.destination_identifier_number}
        </Text>
      ),
    },
  ];

  const metadata = {
    WITHDRAW_BROKER: WITHDRAW_BROKER_Metadata,
    DEPOSIT_BROKER: DEPOSIT_BROKER_Metadata,
    WITHDRAW_BANK_TRANSFER: [],
    DEPOSIT_BANK_TRANSFER: [],
    WITHDRAW_LD: [],
    DEPOSIT_LD: [],
    WITHDRAW_CRYPTO: [],
    DEPOSIT_CRYPTO: [],
    TRANSFER_USER: TRANSFER_Metadata,
    SWAP: [],
    "-": [],
  };

  return (
    <Card>
      {transactionLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions
          title="Metadata"
          bordered
          layout="vertical"
          items={metadata[transaction?.transaction_method.category || "-"]}
        />
      )}
    </Card>
  );
};

export default Metadata;
