import { ErrorResponse, ListResponse, Transaction } from "types";

import { url } from "statics";
import { api } from "utils";

const queyUserTransactions = ({
  params,
  userId,
  isPaginated = false,
}: {
  params: any;
  userId: string;
  isPaginated?: boolean;
}) => {  
  const pageSize = isPaginated ? {} : { pageSize: 10 };
  return api.get<ListResponse<Transaction>, ErrorResponse, any>(
    url.transactions,
    { params: { ...params, user: userId, ...pageSize } }
  );
};

export { queyUserTransactions };
