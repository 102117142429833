import { Card, Descriptions, Flex, Spin, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import dayjs from "dayjs";

type UserInfoProps = {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  identifier_number: number;
  otp_type: string;
  address: string;
  created_at: string;
  isLoading: boolean;
  country: { flag: string; title: string } | null;
};

const UserInfo: React.FC<UserInfoProps> = ({
  first_name,
  last_name,
  username,
  email,
  identifier_number,
  otp_type,
  address,
  created_at,
  country,
  isLoading,
}) => {
  const profileData = [
    {
      key: "-1",
      label: "Country",
      children: country?.flag ? (
        <Flex gap={8}>
          <img src={country.flag} alt={country.title} width="24px" />
          <Typography>{country.title || "-"}</Typography>
        </Flex>
      ) : (
        "User has no country information."
      ),
    },
    {
      key: "0",
      label: "Full name",
      children: <Typography>{first_name + last_name}</Typography>,
    },
    {
      key: "1",
      label: "Username",
      children: (
        <Paragraph copyable={{ tooltips: false }}>{username}</Paragraph>
      ),
    },
    {
      key: "2",
      label: "Email",
      children: <Paragraph copyable={{ tooltips: false }}>{email}</Paragraph>,
    },
    {
      key: "3",
      label: "Identifier number",
      children: (
        <Paragraph copyable={{ tooltips: false }}>
          {identifier_number}
        </Paragraph>
      ),
    },
    {
      key: "4",
      label: "OTP type",
      children: <Typography>{otp_type}</Typography>,
    },
    {
      key: "5",
      label: "Address",
      children: <Typography>{address}</Typography>,
    },
    {
      key: "6",
      label: "Joined at",
      children: (
        <Typography>{dayjs(created_at).format("YYYY-MM-DD") || "-"}</Typography>
      ),
    },
  ];
  return (
    <Card>
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions
          bordered
          layout="vertical"
          title="User Info"
          items={profileData}
        />
      )}
    </Card>
  );
};
export default UserInfo;
