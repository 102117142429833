import React from "react";
import { queyUserTransactions } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import type { Transaction } from "types";
import { Button, Flex } from "antd";
import Title from "antd/es/typography/Title";
import { notification, ProTableRequest, reloadTable } from "utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUser } from "hooks";
import {
  ApproveDeposit,
  ApproveWithdraw,
  ApproveWithdrawCrypto,
  RejectDeposit,
  RejectWithdrawCrypto,
} from "./components";
import { ActionType } from "@ant-design/pro-components";
import { TableButton } from "components";
import { routes } from "statics";
import exportPDF from "utils/exportPDF";

const UserTransactions: React.FC<{ user_id?: string }> = ({ user_id }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const actionRef = React.useRef<ActionType>();
  const [list, setList] = React.useState<any[]>([]);

  const isPaginated = location.pathname.includes("/clients");

  const { data: user } = useUser({ id: userId || user_id || "" });

  const userTransactionsRequest = ProTableRequest<Transaction>(
    ({ params }: { params: any }) =>
      queyUserTransactions({ params, userId: userId || "", isPaginated }),
    setList
  )({});

  const actions = (_: any, item: Transaction) => [
    item.transaction_method.category === "DEPOSIT_BANK_TRANSFER" &&
      item.status === "CLEARED" && (
        <>
          <ApproveDeposit
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectDeposit
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    item.transaction_method.category === "WITHDRAW_BANK_TRANSFER" &&
      item.status === "APPROVED" && (
        <ApproveWithdraw
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
    item.transaction_method.category === "WITHDRAW_CRYPTO" &&
      item.status === "CLEARED" && (
        <>
          <ApproveWithdrawCrypto
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectWithdrawCrypto
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    <TableButton
      title="Detail"
      onClick={() => navigate(routes.transaction.href(item?.id))}
    />,
  ];

  const config: any = isPaginated
    ? {}
    : { options: false, search: false, pagination: false };

  const exportHandler = () => {
    exportPDF({
      data: list.map((item: Transaction) => ({
        type: item.transaction_method.type,
        category: item.transaction_method.category,
        amount: item.amount,
        destination_amount: item.destination_amount,
        fee: item.fee.fee_amount,
        status: item.status,
        created_at: item.created_at,
      })),
      fileName: `transactions-${new Date().getTime()}`,
    });
    notification.success({ message: "Export was successful" });
  };

  return (
    <ProTable<Transaction>
      headerTitle={
        <Flex style={{ width: "100%" }} align="center">
          <Title level={5}>
            {(user?.username
              ? user?.username
              : user?.profile.first_name && user?.profile.last_name
              ? user?.profile.first_name + " " + user?.profile.last_name
              : "") + " transactions"}
          </Title>
        </Flex>
      }
      toolBarRender={() => [
        list.length > 0 && (
          <Button onClick={() => exportHandler()}>Export excel</Button>
        ),
      ]}
      actionRef={actionRef}
      columns={columns({ actions, role: user?.role.title })}
      request={userTransactionsRequest}
      {...config}
    />
  );
};

export default UserTransactions;
