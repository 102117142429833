import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { url } from "statics";

import { api } from "utils";
import type { ErrorResponse } from "types";

const useDeleteContact = (): UseMutationResult<
  {},
  ErrorResponse,
  { userId: string; contactId: string }
> =>
  useMutation(({ contactId, userId }) =>
    api.delete(url.deleteContact(userId, contactId))
  );

export default useDeleteContact;
