import {
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Card, Row, Tag } from "antd";
import { useCurrencies } from "hooks";
import React from "react";
import { generateFormProps } from "utils";

type SearchType = {
  transaction_method?: { type?: string; category?: string };
  status?: string;
  currency?: string;
  username?: string;
  from_date?: string;
  to_date?: string;
};

type SearchFromType = {
  type?: string;
  category?: string;
  status?: string;
  currency?: string;
  username?: string;
  updated_at?: [string, string];
};

const Search: React.FC<{
  setSearch: (data: SearchType) => Promise<URLSearchParams>;
  search: SearchType;
}> = ({ search, setSearch }) => {
  const formRef = React.useRef<any>();
  const { data: currencies } = useCurrencies();

  const currencyList = currencies?.list.map((item) => ({
    value: item.id,
    label: (
      <Row align="middle">
        <img
          src={item.icon}
          width={20}
          height={20}
          alt="icon"
          style={{ marginRight: 4 }}
        />
        {item.title}
      </Row>
    ),
  }));

  const rules = {
    type: [{ required: false }],
    category: [{ required: false }],
    status: [{ required: false }],
    currency: [{ required: false }],
    username: [{ required: false }],
    updated_at: [{ required: false }],
  };
  const formProps = generateFormProps(
    rules,
    {},
    {
      type: "Type",
      category: "Category",
      status: "Status",
      currency: "Currency",
      username: "Username",
      updated_at: "Last update",
    }
  );

  return (
    <Card style={{ marginBottom: 10 }}>
      <ProForm<SearchFromType>
        formRef={formRef}
        onFinish={async (values) => {
          const query = {
            status: values.status,
            currency: values.currency,
            username: values.username,
            from_date: values.updated_at?.[0],
            to_date: values.updated_at?.[1],
            transaction_method: {
              type: values.type,
              category: values.category,
            },
          };
          setSearch(query);
        }}
        initialValues={
          {
            ...search,
            category: search?.transaction_method?.category,
            type: search?.transaction_method?.type,
            updated_at: [search?.from_date, search?.to_date],
          }!
        }
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          flexWrap: "wrap",
        }}
        submitter={{
          searchConfig: { submitText: "Query", resetText: "Reset", },
          onReset: async () => {
            await setSearch({}).then(() => formRef.current.resetFields());
          },
        }}
      >
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.type}
          options={[
            { label: <Tag color="green">Deposit</Tag>, value: "DEPOSIT" },
            { label: <Tag color="red">Withdraw</Tag>, value: "WITHDRAW" },
            { label: <Tag color="purple">Transfer</Tag>, value: "TRANSFER" },
            { label: <Tag color="geekblue">Swap</Tag>, value: "SWAP" },
          ]}
        />
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.category}
          options={[
            {
              label: <Tag color="red">Withdraw bank transfer</Tag>,
              value: "WITHDRAW_BANK_TRANSFER",
            },
            {
              label: <Tag color="green">Deposit bank transfer</Tag>,
              value: "DEPOSIT_BANK_TRANSFER",
            },
            {
              label: <Tag color="red">Withdraw crypto</Tag>,
              value: "WITHDRAW_CRYPTO",
            },
            {
              label: <Tag color="green">Deposit crypto</Tag>,
              value: "DEPOSIT_CRYPTO",
            },
            {
              label: <Tag color="red">Withdraw LD</Tag>,
              value: "WITHDRAW_LD",
            },
            {
              label: <Tag color="green">Deposit LD</Tag>,
              value: "DEPOSIT_LD",
            },
            {
              label: <Tag color="red">Withdraw Broker</Tag>,
              value: "WITHDRAW_BROKER",
            },
            {
              label: <Tag color="green">Deposit Broker</Tag>,
              value: "DEPOSIT_BROKER",
            },
            {
              label: <Tag color="red">Withdraw manual</Tag>,
              value: "WITHDRAW_MANUAL",
            },
            {
              label: <Tag color="green">Deposit manual</Tag>,
              value: "DEPOSIT_MANUAL",
            },
            { label: <Tag color="geekblue">Swap</Tag>, value: "SWAP" },
            {
              label: <Tag color="purple">Transfer</Tag>,
              value: "TRANSFER_USER",
            },
          ]}
        />
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.status}
          options={[
            { label: "Init", value: "INIT" },
            { label: "Authorized", value: "AUTHORIZED" },
            { label: "Unauthorized", value: "UNAUTHORIZED" },
            { label: "Cleared", value: "CLEARED" },
            { label: "Not cleared", value: "NOT_CLEARED" },
            { label: "Approved", value: "APPROVED" },
            { label: "Denied", value: "DENIED" },
            { label: "Settling", value: "SETTLING" },
            { label: "Failed", value: "FAILED" },
            { label: "Settled", value: "SETTLED" },
            { label: "Canceled", value: "CANCELED" },
          ]}
        />
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.currency}
          options={currencyList}
        />
        <ProFormText style={{ width: "305px" }} {...formProps.username} />
        <ProFormDateRangePicker
          style={{ width: "305px" }}
          {...formProps.updated_at}
          placeholder={["Start date", "End date"]}
        />
      </ProForm>
    </Card>
  );
};

export default Search;
