import React from "react";
import { Card, Descriptions, Divider, Flex, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useBalance, useUser } from "hooks";
import {
  UserInfo,
  RoleInfo,
  SensitiveInfo,
  ContactInfo,
  ManualTransaction,
  ChangeUsernameModal,
} from "./components";
import { TableButton } from "components";
import { routes } from "statics";

const User: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [manualTr, setManualTr] = React.useState<null | "deposit" | "withdraw">(
    null
  );

  const { data: user, isLoading, refetch } = useUser({ id: userId || "" });
  const { data: balance } = useBalance({ id: userId || "" });

  return (
    <Flex vertical gap={8} style={{ paddingBottom: 20 }}>
      <Card>
        <Descriptions
          bordered
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Total asset",
              children: <Typography>{balance?.balance || 0} $</Typography>,
            },
            {
              key: "2",
              label: "Shortcuts",
              children: (
                <Flex align="center">
                  <TableButton
                    title="Wallets"
                    onClick={() =>
                      navigate(routes.clientWallets.href(userId || ""))
                    }
                  />
                  <Divider type="vertical" />
                  <TableButton
                    title="Transactions"
                    onClick={() =>
                      navigate(routes.clientTransactions.href(userId || ""))
                    }
                  />
                  <Divider type="vertical" />
                  <TableButton
                    title="Bank Accounts"
                    onClick={() =>
                      navigate(routes.clientBankAccounts.href(userId || ""))
                    }
                  />
                  <Divider type="vertical" />
                  <TableButton
                    title="Sessions"
                    onClick={() =>
                      navigate(routes.clientSession.href(userId || ""))
                    }
                  />
                </Flex>
              ),
            },
            {
              key: "3",
              label: "Actions",
              children: (
                <Flex align="center">
                  <ChangeUsernameModal
                    userId={user?.id || ""}
                    refresh={refetch}
                    username={user?.username || ""}
                  />
                  <TableButton
                    title="Manual Deposit"
                    onClick={() => setManualTr("deposit")}
                  />
                  <TableButton
                    title="Manual Withdraw"
                    onClick={() => setManualTr("withdraw")}
                  />
                </Flex>
              ),
            },
          ]}
        />
      </Card>
      <UserInfo
        address={user?.profile.address || ""}
        created_at={user?.created_at || ""}
        country={user?.country || null}
        email={user?.email || ""}
        first_name={user?.profile.first_name || ""}
        last_name={user?.profile.last_name || ""}
        identifier_number={user?.identifier_number || 0}
        otp_type={user?.profile.otp_type || ""}
        username={user?.username || "-"}
        isLoading={isLoading}
      />
      <RoleInfo
        username={user?.username || "-"}
        role={user?.role.title}
        roleId={user?.role.id || ""}
        userId={user?.id || ""}
        updated_at={user?.role.updated_at || ""}
        ldContract={user?.ld_contract || undefined}
        refetch={refetch}
        isLoading={isLoading}
      />
      <ContactInfo userId={user?.id || ""} isLoading={isLoading} />
      <SensitiveInfo
        is_suspended={user?.is_suspended || null}
        is_kyc_blocked={user?.is_kyc_blocked || null}
        userId={user?.id || ""}
        isLoading={isLoading}
        refetch={refetch}
      />
      {manualTr && (
        <ManualTransaction
          userId={userId!}
          onClose={() => setManualTr(null)}
          type={manualTr}
        />
      )}
    </Flex>
  );
};

export default User;
