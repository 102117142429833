import { Button, Modal, Typography } from "antd";
import { useSetPrimaryContact } from "hooks";
import React from "react";

type SetPrimaryModalProps = {
  contactId: string;
  userId: string;
  refresh: () => void;
  contact: string;
};

const SetPrimaryModal: React.FC<SetPrimaryModalProps> = ({
  contactId,
  userId,
  refresh,
  contact,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { mutateAsync: setPrimary, isLoading: setPrimaryLoading } =
    useSetPrimaryContact();

  const onFinish = async () => {
    setPrimary({ userId, contactId }).then(() => {
      setIsModalOpen(false);
      refresh();
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        type="link"
        style={{ minHeight: "unset", padding: "0 4px" }}
        loading={setPrimaryLoading}
      >
        Set Primary
      </Button>
      <Modal
        open={isModalOpen}
        onOk={async () => onFinish()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={false}
        title="Set Primary Contact"
        cancelText="Close"
        okText="Confirm"
        destroyOnClose
      >
        You want to set
        <Typography.Text style={{ fontWeight: "bold", paddingInline: 5 }}>
          {contact}
        </Typography.Text>
        as the primary contact. Are you sure?
      </Modal>
    </>
  );
};
export default SetPrimaryModal;
